import HELPERS from './helpers/map_helpers.js';
import Cookies from 'js-cookie';

$(document).on('ready turbolinks:load', function() {

  /**
   * Read cookies and re-open previously opened collapsed elements accordingly
   */
  function checkCookiesAndSetVisibleMarkers() {
    let theme = Cookies.get('tlelele_theme');
    let projectId = Cookies.get('tlelele_projectId');
    if(theme) {
      $('.theme-title[data-theme="'+theme+'"').trigger('click');
    }
    if(theme && projectId) {
      $('[data-target="#project-'+theme+'_'+projectId+'"]').trigger('click');
    }
  }
  
  $('.project > a').on('mouseover', function() {
    let dt = $(this).data('target');
    let projectId = dt.split('_').pop();

    HELPERS.hoverForMarkers(Cookies.get('tlelele_theme'), projectId);
  }).on('mouseout', function() {
    HELPERS.hoverForMarkersReset();
  });

  /**
   * Hovering locations in the sidebar - change marker in the map
   */
  $('.location > a').on('mouseover', function() {
    HELPERS.hoverForMarkersLocation($(this).data('locationid'));
  }).on('mouseout', function() {
    HELPERS.hoverForMarkersReset();
  });
  
  /**
   * Theme collapse - open
   * Close all other collapses
   */
  $('.location-theme .collapse[data-theme]').on('shown.bs.collapse', function (event) {
    if (!$(this).is(event.target)) {
      return;
    }

    HELPERS.clearActiveSidebarLocationState();
    $('.location-theme .collapse[data-project-id]').collapse('hide');

    let theme = $(this).data('theme');
    if(theme) {
      HELPERS.toggleMarkersWithPropertyVal({'theme': theme});
      Cookies.set('tlelele_theme', theme);
      Cookies.remove('tlelele_projectId');
    } 
  })

  /**
   * Theme collapse - close
   */
  $('.location-theme .collapse[data-theme]').on('hide.bs.collapse', function (event) {
    if (!$(this).is(event.target)) {
      return;
    }

    let theme = $(this).data('theme');
    if(theme) {
      HELPERS.toggleMarkersWithPropertyVal({'theme': theme}, true);
      Cookies.remove('tlelele_theme');
    } 
  })

  /**
   * Project collapse - open
   */
  $('.location-theme .collapse[data-project-id]').on('shown.bs.collapse', function (event) {
    if (!$(this).is(event.target)) {
      return;
    }

    HELPERS.clearActiveSidebarLocationState();

    let projectId = $(this).data('project-id');
    let parent = $(this).data('parent');
    let theme = $(parent).data('theme');
    if(projectId && theme) {
      HELPERS.toggleMarkersWithPropertyVal({'projectId': projectId, 'theme': theme});
      Cookies.set('tlelele_theme', theme);
      Cookies.set('tlelele_projectId', projectId);
    } 
  })

  /**
   * Project collapse - close
   */
  $('.location-theme .collapse[data-project-id]').on('hide.bs.collapse', function (event) {
    if (!$(this).is(event.target)) {
      return;
    }

    let projectId = $(this).data('project-id');
    if(projectId) {
      HELPERS.toggleMarkersWithPropertyVal({'projectId': projectId}, true);
      Cookies.remove('tlelele_projectId');
    } 
  })

  /**
   * Fill location popup with relevant content before show
   */
  $('#locationModal').on('show.bs.modal', function(event) {
    let $relTarget = $(event.relatedTarget);
    let imgUrl = $relTarget.data('img-url'); 
    let url = $relTarget.data('url');
    let address = $relTarget.data('address');
    let title = $relTarget.html();
    let locationId = $relTarget.data('locationid');

    let modal = $(this);
    modal.find('.modal-title').text(title);
    modal.find('.modal-subtitle').text(address);
    modal.find('img').attr('src', imgUrl);
    modal.find('.go-to-location').attr('href', url);

    HELPERS.addActiveSidebarLocationState(locationId);
  })

  /**
   * Remove active state from 
   */
  $('#locationModal').on('hide.bs.modal', function() {
    HELPERS.clearActiveSidebarLocationState();
  })

  /**
   * Initialize map when google maps api is ready
   */
  window.initMap = function() {
    if(!document.getElementById(HELPERS.SETTINGS.mapId)) {
      return;
    }
    HELPERS.SETTINGS.map = new google.maps.Map(document.getElementById(HELPERS.SETTINGS.mapId), HELPERS.SETTINGS.mapOptions);
    let mapCenter = new google.maps.LatLng(HELPERS.SETTINGS.mapCenter.lat, HELPERS.SETTINGS.mapCenter.lng);
    HELPERS.SETTINGS.map.setCenter(mapCenter);

    let markers = HELPERS.getMapMarkers();

    for(let i=0; i<markers.length; i++) {
      let marker = new google.maps.Marker({
        position: markers[i].latLng,
        title: markers[i].title + ' - ' + markers[i].location,
        icon: markers[i].icon,
        map: HELPERS.SETTINGS.map,
      });
      marker['projectId'] = markers[i].projectId;
      marker['theme'] = markers[i].theme;
      marker['role'] = markers[i].role;
      marker['locationId'] = markers[i].locationId;

      HELPERS.SETTINGS.markers[i] = marker;

      marker.addListener('click', function() {
        HELPERS.openPopup(HELPERS.SETTINGS.markers[i].locationId);
        HELPERS.clearActiveSidebarLocationState();
        HELPERS.addActiveSidebarLocationState(HELPERS.SETTINGS.markers[i].locationId)
      });

      marker.addListener('mouseover', function() {
        HELPERS.addActiveSidebarLocationState(HELPERS.SETTINGS.markers[i].locationId);
      });

      marker.addListener('mouseout', function() {
        HELPERS.clearActiveSidebarLocationState();
      });

      // if we want to hide all the markers by default uncomment the next line
      //marker.setMap(null);
    }
    checkCookiesAndSetVisibleMarkers();
  }

  /**
   * Load Gmaps script manually when turbolinks already loaded.
   * If used within header as async defer it did not work consistently on Safari.
   */
  if(window.google){
    window.initMap();
  } else{
    $.ajax('https://maps.googleapis.com/maps/api/js?key=AIzaSyCqjXl9-Ta_yNZ1Vs54qhWz7Y2ezl3ZVmc&callback=initMap', {
      crossDomain: true,
      dataType: 'script'
    });
  }
  
})
