/**
 * Our BS carousel indicators are out of our '.carousel' class so '.active' classes
 * don't automatically apply to li elements.
 * We do that here.
 */
$(document).on('ready turbolinks:load', function() {
  let $carouselIndicators = $('.tle-carousel-indicators [data-slide-to]');
  $('#homeCarousel').on('slide.bs.carousel', function (el) {
    $carouselIndicators.removeClass('active');
    $('.tle-carousel-indicators [data-slide-to="'+el.to+'"').addClass('active');
  });
});
