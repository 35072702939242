export default {
  SETTINGS: {
    map: '',
    mapId: 'map',
    locationDataDivId: 'location_data', // needs to have data-locations attr
    mapCenter: {
      lat: 45.805528,
      lng: 15.164786,
    },
    mapOptions: {
      zoom: 14,
      disableDefaultUI: true,
      zoomControl: true,
    },
    markers: [],
    markersVisible: [],
    activeTheme: '',
  },

  hideAllMarkers() {
    this.SETTINGS.markers.filter(function(marker) {
      marker.setMap(null);
    });
    this.SETTINGS.markersVisible = [];
  },

  showAllMarkers() {
    let th = this;
    this.SETTINGS.markers.filter(function(marker) {
      marker.setMap(th.SETTINGS.map);
      th.SETTINGS.markersVisible.push(marker);
    });
  },

  openPopup(location_id) {
    $('#location-'+location_id).find('a').click();
  },

  toggleMarkersWithPropertyVal(propertyValueObject, forceHide = false) {
    let msg = (forceHide ? "HIDE" : "Show") + " markers with";

    for (let key of Object.keys(propertyValueObject)) {
      //console.log(key + ": " + propertyValueObject[key]);
      msg += key + ": " + propertyValueObject[key] + " | ";

      if(key === 'theme') {
        if(forceHide) {
          this.activeTheme = '';
        } else {
          this.activeTheme = propertyValueObject[key];
        }
      }

    }

    //console.log("active theme: "+this.activeTheme);

    if(this.activeTheme === '' || this.activeTheme === null) {
      this.showAllMarkers();
      return;
    }

    let thisTmp = this;
    let filteredMarkers = this.SETTINGS.markers.filter(function(marker) {
      if(thisTmp.objectParamsMatch(marker, propertyValueObject) && !forceHide) {
        marker.setMap(thisTmp.SETTINGS.map);
        return marker;
      } else {
        marker.setMap(null);
      }
    });
    this.SETTINGS.markersVisible = filteredMarkers;
    //console.log("-> ("+filteredMarkers.length+")");

    // when 0 markers filtered and we have active theme,
    // then we most likely closed one specific marker (project) and now have to 
    // show all markers from the parent theme again
    if(filteredMarkers.length === 0) {
      this.toggleMarkersWithPropertyVal({'theme': this.activeTheme})
    }

  },

  hoverForMarkers(theme, projectId) {
    let thisTmp = this;
    this.SETTINGS.markersVisible.forEach(function(marker, index) {
      if(thisTmp.objectParamsMatch(marker, {'projectId': parseInt(projectId)})) {
        marker.setAnimation(google.maps.Animation.BOUNCE);
      }
    });
  },

  hoverForMarkersLocation(locationid) {
    if(!locationid) {
      return;
    }
    let thisTmp = this;
    this.SETTINGS.markersVisible.forEach(function(marker, index) {
      if(thisTmp.objectParamsMatch(marker, {'locationId': locationid})) {
        marker.setAnimation(google.maps.Animation.BOUNCE);
      }
    });
  },

  hoverForMarkersReset() {
    this.SETTINGS.markersVisible.forEach( marker => marker.setAnimation(null) );
  },

  objectParamsMatch(markerObject, matchObject) {
    for (let key of Object.keys(matchObject)) {
      if(markerObject[key] !== matchObject[key]) {
        return false;
      }
    }
    return true;
  },

  clearActiveSidebarLocationState() {
    $('.accordion .project li').removeClass('font-weight-bold');
  },

  addActiveSidebarLocationState(location_id) {
    $('#location-'+location_id).addClass('font-weight-bold');
  },

  getMapMarkers() {
    let locations = $('#'+this.SETTINGS.locationDataDivId).data('locations');

    let markers = [];

    for(let i=0; i<locations.length; i++) {
      let l = locations[i];

      markers[i] = {
        'latLng': new google.maps.LatLng(l.latitude, l.longitude),
        'title': l.title,
        'icon': l.icon,
        'id': l.project_id+'-'+l.id,
        'location': l.location,
        'theme': l.theme,
        'role': l.role,
        'projectId': l.project_id,
        'locationId': l.id,
      };
    }

    return markers;
  }

}
